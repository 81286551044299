/*!
 * Parsley Locale
 * Author: Globalia
 * Licensed under the WTFPL license
 */


( function( parsley ) {

    if (document.documentElement.lang.indexOf('fr') !== -1) {
        window.Parsley.setLocale('fr');  
    } else {
        window.Parsley.setLocale('en');  
    }

}(
    require('../../../node_modules/parsleyjs/dist/parsley.min.js'),
    require('../../../node_modules/parsleyjs/dist/i18n/fr.js')
));
