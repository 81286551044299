var jquery = jQuery = $ = require('jquery');

var slick = require('slick-carousel-browserify');

require('../../ground/scripts/buds/gr-snippets.js');
require('../../ground/scripts/buds/gr-equalizer.js');
require('../../ground/scripts/buds/gr-inline-svg.js');
require('../../ground/scripts/buds/gr-lazy-loader.js');
require('../../ground/scripts/buds/gr-nav.js');
require('../../ground/scripts/buds/gr-objectfit.js');
require('../../ground/scripts/buds/gr-modal.js');
require('../../ground/scripts/buds/gr-expander.js');
require('./gr-parsley-locale.js');
// require('../../ground/scripts/buds/gr-googlemap.js');
// require('../../ground/scripts/buds/gr-video-bg.js');


( function( $, window, slick) {
    "use strict";

    window.sassBreakpoints = $('[gr-handler]').sassToJs({
        pseudoEl: ':before',
        cssProperty: 'content',
        debug: true
    });

    // Navigation

    $(window).on('scroll load', function() {
        if ($(window).scrollTop() > 10) {
            $(document).find('[gr-nav]').addClass('is-scrolled');
        } else {
            $(document).find('[gr-nav]').removeClass('is-scrolled');
        }
    });

    $('.nav__langswitch').on('click', function() {
        $(this).toggleClass('is-active');
    });

    $('.nav__main > li').on('click', function() {
        $(this)
            .toggleClass('is-active')
            .siblings()
            .removeClass('is-active');
    });

    $('.nav__panel .nav__link').on('click', function(e) {
        e.preventDefault();
    });

    // Alerts

    $('[class^="alert--"], [class*=" alert--"], .hs-common-confirm-message').on('click', function() {
        $(this).slideToggle();
    });

    // Sliders

    slick($('#testimonials'), {
        dots: true,
        speed: 500,
        prevArrow: '<button class="slick-arrow slick-prev" type="button" aria-label="Previous"></button>',
        nextArrow: '<button class="slick-arrow slick-next" type="button" aria-label="Next"></button>'
    });

    // Hubspot form binding
    $(window).on('load', function() {
        $('form')
            .attr('gr-form', '')
            .addClass('form');

        require('../../ground/scripts/buds/gr-form.js');
    });

}(jquery, window, slick));
